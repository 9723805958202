import { AccountType } from "@ll-web/features/auth/types";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { linkToWizard } from "@ll-web/features/projectWizard/utils/linkToWizard";
import type { ReviewEmailTemplateArgs } from "@ll-web/features/reviews/types";
import {
  emailGreeting,
  emailSignature,
  htmlEmailBody,
} from "@ll-web/utils/helpers/emails";
import { propertiesDefined, type Nullable } from "@ll-web/utils/types/types";

export const creativeBriefInternalEmail = ({
  brandName,
  project,
  reviewEmailTemplateUsers,
}: ReviewEmailTemplateArgs<{
  brandName?: string;
  project?: ProjectWithDeliverables;
}>) => {
  const requiredBodyParams = {
    projectMsa: project?.msa,
    projectName: project?.title,
    projectId: project?.id,
    brandName,
  };

  return {
    recipientUserIds: reviewEmailTemplateUsers.recipientUserIds,
    title: `A new project ${brandName ? `for ${brandName}` : ""} is ready for your review`,
    body: emailBody({
      requiredBodyParams,
      reviewEmailTemplateUsers,
    }),
  };
};

const emailBody = ({
  requiredBodyParams,
  reviewEmailTemplateUsers: { defaultRecipient, brandUsers },
}: ReviewEmailTemplateArgs<{
  requiredBodyParams: {
    projectMsa: Nullable<string>;
    projectName: Nullable<string>;
    projectId: Nullable<string>;
    brandName: Nullable<string>;
  };
}>) => {
  if (!propertiesDefined(requiredBodyParams)) {
    return "";
  }

  const accountExecutiveFirstName =
    brandUsers[AccountType.AccountExecutive]?.user?.firstName;
  const accountExecutiveFirstNameText = accountExecutiveFirstName
    ? ` to the AE, ${accountExecutiveFirstName}`
    : ".";
  const wizardLink = linkToWizard(requiredBodyParams.projectId);

  return htmlEmailBody(`${emailGreeting("", defaultRecipient?.user?.firstName)}
<br><br>The project details have been submitted for:
<br><br>${requiredBodyParams.brandName}
<br>${requiredBodyParams.projectMsa}, ${requiredBodyParams.projectName}.
<br>Review it <a href="${wizardLink}">here.</a>
<br><br>Please review the output and prepare the documents for the client prior to the Creative Review call.
<br><br>If you have any questions or need more information, feel free to reach out${accountExecutiveFirstNameText}.
<br><br>${emailSignature("Best", "Lemonlight")}`);
};

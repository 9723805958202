import type { AxiosRequestConfig } from "axios";

import { httpClient } from "@ll-web/core/api/HttpClient";
import {
  type CreateApprovalDto,
  type CreateReviewDto,
  type GetReviewArgs,
  type ReviewResourceResponse,
} from "@ll-web/features/reviews/types";

class ReviewsService {
  public async getReview(
    args: GetReviewArgs,
    requestConfig?: AxiosRequestConfig,
  ): Promise<ReviewResourceResponse> {
    return await httpClient.unwrappedHttpRequest<ReviewResourceResponse>({
      config: {
        ...requestConfig,
        method: "GET",
        url: `/v1/reviews/${args.reviewKey}`,
        data: args,
      },
    });
  }

  public async requestReview(
    args: CreateReviewDto,
    requestConfig?: AxiosRequestConfig,
  ): Promise<ReviewResourceResponse> {
    return await httpClient.unwrappedHttpRequest<ReviewResourceResponse>({
      config: {
        ...requestConfig,
        method: "POST",
        url: `/v1/reviews/requests`,
        data: args,
      },
    });
  }

  public async approveReview(
    args: CreateApprovalDto,
    requestConfig?: AxiosRequestConfig,
  ): Promise<ReviewResourceResponse> {
    return await httpClient.unwrappedHttpRequest<ReviewResourceResponse>({
      config: {
        ...requestConfig,
        method: "POST",
        url: `/v1/reviews/approvals`,
        data: args,
      },
    });
  }
}

export const reviewsService = new ReviewsService();

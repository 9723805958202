import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { REVIEW_FORM_BLOCKING_PHRASES } from "@ll-web/features/reviews/consts";
import type { ReviewEmailTemplateArgs } from "@ll-web/features/reviews/types";
import {
  emailGreeting,
  emailSignature,
  htmlEmailBody,
} from "@ll-web/utils/helpers/emails";
import { propertiesDefined, type Nullable } from "@ll-web/utils/types/types";

export const editGeneratedDocInternalEmail = ({
  generatedDocName,
  generatedDocUrl,
  generatedDocLinkText,
  project,
  reviewEmailTemplateUsers,
}: ReviewEmailTemplateArgs<{
  generatedDocName: string | null;
  generatedDocUrl: string;
  generatedDocLinkText: string;
  project?: ProjectWithDeliverables;
}>) => {
  const requiredBodyParams = {
    projectName: project?.title,
    generatedDocName,
    generatedDocUrl,
    generatedDocLinkText,
  };

  return {
    recipientUserIds: reviewEmailTemplateUsers.recipientUserIds,
    title: `Great news! ${generatedDocName}${requiredBodyParams.projectName ? ` for ${requiredBodyParams.projectName}` : ""} is ready for final approval`,
    body: emailBody({
      requiredBodyParams,
      reviewEmailTemplateUsers,
    }),
  };
};

const emailBody = ({
  requiredBodyParams,
  reviewEmailTemplateUsers: { defaultRecipient },
}: ReviewEmailTemplateArgs<{
  requiredBodyParams: {
    projectName: Nullable<string>;
    generatedDocName: Nullable<string>;
    generatedDocUrl: Nullable<string>;
    generatedDocLinkText: Nullable<string>;
  };
}>) => {
  if (!propertiesDefined(requiredBodyParams)) {
    return "";
  }

  return htmlEmailBody(`${emailGreeting("", defaultRecipient?.user?.firstName)}
<br/><br/>I'm excited to let you know that the ${requiredBodyParams.generatedDocName} for your ${requiredBodyParams.projectName} project is now finalized. Please take a moment to review:
<br><br><a href="${requiredBodyParams.generatedDocUrl}">${requiredBodyParams.generatedDocName}</a>: ${requiredBodyParams.generatedDocLinkText}
<br><br>You can approve the documents by ${REVIEW_FORM_BLOCKING_PHRASES.date.text}. If you have any additional feedback before you approve, you may also leave comments directly in the platform.
<br><br>Let us know if you have any questions. Looking forward to your feedback!
<br><br>${emailSignature("Best", "Lemonlight")}`);
};

import { useMemo } from "react";

import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import {
  useGetBrandById,
  useGetBrandByProjectId,
} from "@ll-web/features/brands/async/useBrandsQueries";
import { editGeneratedDocExternalModalTemplate } from "@ll-web/features/projectSpace/reviews/modalTemplates/editGeneratedDocExternalModalTemplate";
import { editGeneratedDocInternalModalTemplate } from "@ll-web/features/projectSpace/reviews/modalTemplates/editGeneratedDocInternalModalTemplate";
import { ReviewEditGeneratedDocsKey } from "@ll-web/features/projectSpace/reviews/reviewGeneratedDocsKeys";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";
import { useNotifyForReviewDialog } from "@ll-web/features/reviews/hooks/useNotifyForReviewModal";
import type { ReviewEmailData } from "@ll-web/features/reviews/types";

import { creativeBriefInternalModalTemplate } from "./modalTemplates/creativeBriefInternalModalTemplate";
import { wizardGenerateExternalModalTemplate } from "./modalTemplates/wizardGenerateExternalModalTemplate";
import { wizardGenerateInternalModalTemplate } from "./modalTemplates/wizardGenerateInternalModalTemplate";
import { ReviewWizardFlowKey } from "./reviewWizardFlowKeys";

type UseReviewWizardFlowArgs = {
  onSubmit: (payload: ReviewEmailData) => Promise<void>;
  notificationType: ReviewWizardFlowKey | ReviewEditGeneratedDocsKey;
  page: string;
};

export const useReviewWizardFlow = ({
  onSubmit,
  notificationType,
  page,
}: UseReviewWizardFlowArgs) => {
  const { activeUser } = useActiveUser();
  const { activeProject } = useActiveProject();

  const brandByProjectQuery = useGetBrandByProjectId({
    projectId: activeProject.id,
  });
  const brandId = brandByProjectQuery.data?._id;

  const brandByIdQuery = useGetBrandById(
    {
      brandId: brandId!,
    },
    { enabled: !!brandId },
  );

  const projectTeam = useMemo(() => {
    return (
      brandByIdQuery?.data?.team?.filter((teamMember) => !!teamMember.user) ??
      []
    );
  }, [brandByIdQuery?.data?.team]);

  const reviewModalTemplate = useMemo(() => {
    const sharedArgs = {
      activeUser,
      page,
      project: activeProject,
      users: projectTeam,
    };

    switch (notificationType) {
      case ReviewWizardFlowKey.CreativeBriefInternal:
        return creativeBriefInternalModalTemplate({
          ...sharedArgs,
          brandName: brandByIdQuery?.data?.name,
        });
      case ReviewWizardFlowKey.WizardGenerateExternal:
        return wizardGenerateExternalModalTemplate(sharedArgs);
      case ReviewWizardFlowKey.WizardGenerateExternalLegacy:
        return wizardGenerateExternalModalTemplate({
          ...sharedArgs,
          isLegacyFlow: true,
        });
      case ReviewWizardFlowKey.WizardGenerateInternal:
        return wizardGenerateInternalModalTemplate({
          ...sharedArgs,
          brandName: brandByIdQuery?.data?.name,
        });
      case ReviewEditGeneratedDocsKey.CreativeDeckEditInternal:
      case ReviewEditGeneratedDocsKey.CallSheetEditInternal:
        return editGeneratedDocInternalModalTemplate({
          reviewKey: notificationType,
          ...sharedArgs,
        });
      case ReviewEditGeneratedDocsKey.CreativeDeckEditExternal:
      case ReviewEditGeneratedDocsKey.CallSheetEditExternal:
        return editGeneratedDocExternalModalTemplate({
          reviewKey: notificationType,
          ...sharedArgs,
        });
      default:
        throw new Error(
          `Wizard flow for ${notificationType} has not been defined`,
        );
    }
  }, [
    activeUser,
    brandByIdQuery?.data?.name,
    activeProject,
    projectTeam,
    notificationType,
    page,
  ]);

  return useNotifyForReviewDialog({
    onSubmit,
    reviewModalTemplate,
    isLoading: brandByIdQuery.isFetching,
  });
};

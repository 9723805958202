import { AccountType } from "@ll-web/features/auth/types";
import {
  BrandRoleEnum,
  type BrandUserWithProfile,
} from "@ll-web/features/brands/types";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { editGeneratedDocInternalEmail } from "@ll-web/features/projectSpace/reviews/emailTemplates/editGeneratedDocInternalEmail";
import type { ReviewEditGeneratedDocsKey } from "@ll-web/features/projectSpace/reviews/reviewGeneratedDocsKeys";
import {
  reviewDefaultHeading,
  ReviewModalTitle,
} from "@ll-web/features/reviews/consts";
import type { ReviewModalTemplate } from "@ll-web/features/reviews/types";
import { getReviewEmailUsers } from "@ll-web/features/reviews/utils/getReviewEmailUsers";

import { reviewEditDocumentsToastParams } from "./common/reviewEditDocsTexts";
import { generatedDocData } from "./common/reviewGeneratedDocsUtils";

type EditGeneratedDocInternalModalTemplateArgs = {
  users: BrandUserWithProfile[];
  project: ProjectWithDeliverables;
  reviewKey: ReviewEditGeneratedDocsKey;
};

export const editGeneratedDocInternalModalTemplate = ({
  users,
  project,
  reviewKey,
}: EditGeneratedDocInternalModalTemplateArgs): ReviewModalTemplate => {
  const { generatedDocName, generatedDocUrl, generatedDocLinkText } =
    generatedDocData({
      notificationType: reviewKey,
      projectId: project.id,
    });

  return {
    reviewEmailData: editGeneratedDocInternalEmail({
      ...getReviewEmailUsers({
        users,
        recipientsTypes: [
          BrandRoleEnum.Owner,
          AccountType.Producer,
          AccountType.AccountExecutive,
        ],
      }),
      generatedDocName,
      generatedDocUrl,
      generatedDocLinkText,
      project,
    }),
    titleContent: ReviewModalTitle.notifyClientForReview,
    headingContent: reviewDefaultHeading,
    toastParams: generatedDocName
      ? reviewEditDocumentsToastParams(generatedDocName)
      : undefined,
  };
};

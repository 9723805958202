import { ActivityType } from "@ll-web/core/analytics/events";
import { AccountType, type User } from "@ll-web/features/auth/types";
import { type BrandUserWithProfile } from "@ll-web/features/brands/types";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { creativeBriefInternalEmail } from "@ll-web/features/projectWizard/reviews/emailTemplates/creativeBriefInternalEmail";
import { ReviewModalTitle } from "@ll-web/features/reviews/consts";
import type { ReviewModalTemplate } from "@ll-web/features/reviews/types";
import { getReviewEmailUsers } from "@ll-web/features/reviews/utils/getReviewEmailUsers";

import { reviewWizardAnalyticsMetadata } from "./common/reviewWizardAnalyticsMetadata";
import { reviewWizardToastParams } from "./common/reviewWizardTexts";

type CreativeBriefInternalModalTemplateArgs = {
  users: BrandUserWithProfile[];
  activeUser: User;
  brandName?: string;
  project?: ProjectWithDeliverables;
  page: string;
};

export const creativeBriefInternalModalTemplate = ({
  brandName,
  users,
  project,
  activeUser,
  page,
}: CreativeBriefInternalModalTemplateArgs): ReviewModalTemplate => {
  return {
    reviewEmailData: creativeBriefInternalEmail({
      ...getReviewEmailUsers({
        users,
        recipientsTypes: [
          AccountType.Producer,
          AccountType.Creative,
          AccountType.AccountExecutive,
        ],
      }),
      project,
      brandName,
    }),
    titleContent: ReviewModalTitle.notifyProducerForReview,
    warningContent:
      "You are about to submit the creative input for the producer's review. Before submitting, please review all details with the client during your Account Setup Call.",
    headingContent: "",
    toastParams: reviewWizardToastParams,
    analyticsMetadata: reviewWizardAnalyticsMetadata({
      submitType: ActivityType.CreativeBriefInputClickedSubmitForReview,
      cancelType: ActivityType.CreativeBriefInputCanceledSubmittingForReview,
      activeUser,
      page,
      project,
    }),
  };
};
